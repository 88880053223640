// UserManagementPage.js

import React, { useState, useEffect } from 'react';
import './UserManagementPage.css'; // Ensure you create and style this component
import UserForm from '../components/UserForm';

const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editableUser, setEditableUser] = useState(null);

  const openAddUserForm = () => {
    setEditableUser(null);
    setIsFormOpen(true);
  };

  const openEditUserForm = (user) => {
    setEditableUser(user);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEditableUser(null);
  };

  // Fetch the list of users from the backend
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Handlers for user management actions (add)
  const handleAddUser = async (newUserData) => {
    try {
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(newUserData)
      });

      if (!response.ok) {
        throw new Error('Error creating user');
      }

      // Reload or update users list
      fetchUsers();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handlers for user management actions (edit)
  const handleEditUser = async (userId, updatedUserData) => {
    try {
      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/update/${userId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(updatedUserData)
      });

      if (!response.ok) {
        throw new Error('Error updating user');
      }

      // Reload or update users list
      fetchUsers();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handlers for user management actions (delete)
  const handleDeleteUser = async (userId) => {
    if (!window.confirm('Are you sure you want to delete this user?')) {
      return;
    }

    try {
      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/delete/${userId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Error deleting user');
      }

      // Reload or update users list
      fetchUsers();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // ...

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-management-page">
      <h1>User Management 👤</h1>

      {/* ... Display users ... */}
      <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Username</th>
          <th>Role</th>
          <th>Actions</th> {/* Add a column for actions */}
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <tr key={user._id}>
            <td>{user._id}</td>
            <td>{user.username}</td>
            <td>{user.role}</td>
            <td>
              <button onClick={() => openEditUserForm(user)}>Edit</button> {/* Edit button */}
              <button onClick={() => handleDeleteUser(user._id)}>Delete</button> {/* Delete button */}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    
      <button onClick={openAddUserForm}>Add User</button>
      {isFormOpen && (
        <UserForm
          initialData={editableUser}
          onSubmit={editableUser ? (data) => handleEditUser(editableUser._id, data) : handleAddUser}
          onClose={closeForm}
        />
      )}
      {/* Implement modal or separate forms for user creation/editing */}
    </div>
  );
};

export default UserManagementPage;
