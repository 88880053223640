// DashboardPage.js

import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import OrderTable from '../components/OrderTable';
import StatsDisplay from '../components/StatsDisplay';
import './DashboardPage.css'; // Make sure you create and import this CSS file

const DashboardPage = ({ onLogout }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [stats, setStats] = useState({ today: 0, yesterday: 0, dailyAvg: 0, weeklyAvg: 0 });
  const [token, setToken] = useState(localStorage.getItem('token'));


  useEffect(() => {
    fetchStats();
  }, []); // fetchStats only on component mount

  useEffect(() => {
    fetchOrders(currentPage, searchTerm);
  }, [currentPage, searchTerm]); // fetchOrders on currentPage or searchTerm change

  const fetchStats = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/orders/stats`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch stats');
      }

      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const fetchOrders = async (page, search) => {
    setLoading(true);
    setError('');

    try {
      const token = localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        page,
        search,
      });

      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/orders?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch orders');
      }

      const data = await response.json();
      setOrders(data.orders);
      setTotalPages(data.totalPages); // Assume your backend provides this
      // You may also fetch and set stats here if your backend provides them in the response
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    if (e.key === 'Enter') {
      setSearchTerm(e.target.value);
      setCurrentPage(1); // Reset to first page with new search
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Render method
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="dashboard-container">
      <NavBar onLogout={onLogout} />
      <h2>// dashboard</h2>
      <StatsDisplay stats={stats} />
      <OrderTable
        orders={orders}
        onSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        token={token}
      />
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(number => (
          <button
            key={number}
            onClick={() => handlePageChange(number)}
            disabled={number === currentPage}
            className={`page-item ${number === currentPage ? 'active' : ''}`}
          >
            {number}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DashboardPage;
