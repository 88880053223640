// steps/StepSuccess.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const StepSuccess = ({ onNewSubmission }) => {
  const navigate = useNavigate();

  const handleNewSubmission = () => {
    onNewSubmission(); // Reset the form state
    navigate('/submit-order'); // Navigate to the start of the form
  };

  return (
    <div className="step-success">
      <h2>Successfully submitted</h2>
      <button onClick={handleNewSubmission}>+ new submission</button>
      <button onClick={() => navigate('/dashboard')}>view submissions</button>
    </div>
  );
};

export default StepSuccess;