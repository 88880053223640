// steps/StepCustomerInfo.js

import React, { useState, useEffect } from 'react';
import './StepCustomerInfo.css'; // Ensure the corresponding CSS file is created for styling
import './DarkModeSelect.css'; // Import your custom CSS file
import ReactSelect from 'react-select';

const StepCustomerInfo = ({ onNext, onPrevious, orderData }) => {
  const [customerInfo, setCustomerInfo] = useState({
    personalInfo: {
      fullName: '',
      icNumber: '',
      contactNumber: '',
      email: '',
      whatsappNumber: '',
      homeNumber: '',
    },
    billAddress: {
      unitNo: '',
      street: '',
      section: '',
      postcode: '',
      city: '',
      state: '',
      latitude: '',
      longitude: '',
      isHighRise: false,
      floorNumber: '',
      buildingName: '',
    },
    installationDetails: {
      installationDate: '',
      package: '',
    },
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [products, setProducts] = useState([]);

  // state variable for high-rise toggle
  const [isHighRise, setIsHighRise] = useState(false);



  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
  
        if (response.ok) {
          let data = await response.json();
  
          // Filter products based on isVisible status (true for visible)
          const visibleProducts = data.filter(product => product.isVisible === true);
  
          // Sort the visible products by priority (ascending) and then by name (alphabetically)
          visibleProducts.sort((a, b) => {
            if (a.priority === b.priority) {
              return a.name.localeCompare(b.name);
            }
            return a.priority - b.priority;
          });
  
          setProducts(visibleProducts);
        } else {
          console.error('Failed to fetch products');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    fetchProducts();
  }, []);  


  // High rise toggle
  const handleHighRiseToggle = (e) => {
    setIsHighRise(e.target.checked);
  };


  // Create options for React Select
  const productOptions = products.map(product => ({
    label: `${product.name} (RM${product.value})`,
    value: product._id
  }));

  // Set the selected package
  useEffect(() => {
    if (orderData.customerInfo.installationDetails.package) {
      const selectedProductOption = productOptions.find(option => option.value === orderData.customerInfo.installationDetails.package);
      setSelectedPackage(selectedProductOption);
    }
  }, [products, orderData.customerInfo.installationDetails.package]);


  useEffect(() => {
    // Initialize state with orderData if it exists
    if (orderData && orderData.customerInfo) {
      setCustomerInfo(orderData.customerInfo);
    }
  }, [orderData]);


  // Handler for React Select change
  const handleSelectChange = selectedOption => {
    // Update the selected package state
    setSelectedPackage(selectedOption);

    // Update the customer info state with the new package value
    setCustomerInfo({
      ...customerInfo,
      installationDetails: {
        ...customerInfo.installationDetails,
        package: selectedOption.value
      }
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setCustomerInfo(prevState => ({
        ...prevState,
        billAddress: {
          ...prevState.billAddress,
          [name]: checked,
        }
      }));
    } else {
      // Handle other types of inputs
      if (name in customerInfo.personalInfo) {
        setCustomerInfo({
          ...customerInfo,
          personalInfo: {
            ...customerInfo.personalInfo,
            [name]: value
          }
        });
      } else if (name in customerInfo.billAddress) {
        setCustomerInfo({
          ...customerInfo,
          billAddress: {
            ...customerInfo.billAddress,
            [name]: value
          }
        });
      } else {
        // Handle the installationDetails fields
        setCustomerInfo({
          ...customerInfo,
          installationDetails: {
            ...customerInfo.installationDetails,
            [name]: value
          }
        });
      }
    }
  };



  const handleDateChange = (e) => {
    setCustomerInfo({ ...customerInfo, installationDate: e.target.value });
  };

  const handleMagicFill = async () => {
    try {
      const text = await navigator.clipboard.readText();

      // Extract data using regular expressions
      const nameMatch = text.match(/Nama Pelanggan \(mengikut IC\):\s*(.+?)\s*/);
      const icMatch = text.match(/No\. IC:\s*([\d-]+)/);
      const contactMatch = text.match(/Nombor Utk Dihubungi:\s*([\d-]+)/);
      const whatsappMatch = text.match(/Nombor Anda Guna Utk WhatsApp:\s*([\d-]+)/);
      const emailMatch = text.match(/Email:\s*(.+?)\s*\n/);
      const addressMatch = text.match(/Alamat Pemasangan \(HARUS isi alamat sama dengan alamat di bil utiliti\):\s*(.+?)\s*\n/);
      const packageMatch = text.match(/Package nak ambil:\s*(.+?)\s*\n/);
      const installationDateMatch = text.match(/Tarikh Pemasangan Bersesuaian:\s*(.+?)\s*\n/);
      const longitudeLatitudeMatch = text.match(/Longitude & Latitude:\s*(\d+\.\d+),\s*(\d+\.\d+)\s*\n/);
      const addressStatusMatch = text.match(/Address Status:\s*(.+?)\s*\n/);

      setErrorMessage('');

      // Check if the req fields exist

      if (!nameMatch /* ... other conditions */) {
        // If any required field is missing, set an error message
        console.error('Whatsapp form not detected');
        setErrorMessage('Whatsapp form not detected');
        setTimeout(() => {
          document.querySelector('.error-message').classList.add('fade-out');
        }, 1000); // Start fading out after 1 second

        setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
        return;
      }

      // Update the state based on the matches

      if (addressMatch) {
        setIsLoading(true); // Start loading
        const address = addressMatch[1];
        const request = {
          'address': {
            'regionCode': 'MY',
            'addressLines': [address]
          }
        };

        fetch('https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyBMPTKOusjZb_7WezWW1EdkalH_LH39C94', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(request)
        })
          .then(response => response.json())
          .then(data => {
            if (data.result) {
              const validatedAddress = data.result.address.postalAddress;

              // Update the state with the validated address
              setCustomerInfo(prevState => ({
                ...prevState,
                billAddress: {
                  ...prevState.billAddress,
                  unitNo: validatedAddress.addressLines.join(', '),
                  street: validatedAddress.addressLines.join(', '),
                  section: validatedAddress.sublocality,
                  postcode: validatedAddress.postalCode,
                  city: validatedAddress.locality,
                  state: validatedAddress.administrativeArea.trim().toUpperCase(),
                  latitude: data.result.geocode.location.latitude,
                  longitude: data.result.geocode.location.longitude
                },
                personalInfo: {
                  ...prevState.personalInfo,
                  fullName: nameMatch ? nameMatch[1] : prevState.personalInfo.fullName,
                  icNumber: icMatch ? icMatch[1] : prevState.personalInfo.icNumber,
                  contactNumber: contactMatch ? contactMatch[1] : prevState.personalInfo.contactNumber,
                  whatsappNumber: whatsappMatch ? whatsappMatch[1] : prevState.personalInfo.whatsappNumber,
                  email: emailMatch ? emailMatch[1] : prevState.personalInfo.email
                },
                installationDetails: {
                  ...prevState.installationDetails,
                  package: packageMatch ? packageMatch[1] : prevState.installationDetails.package,
                  installationDate: installationDateMatch ? installationDateMatch[1] : prevState.installationDetails.installationDate
                },
                // Add any other fields you need to update
              }));
            }
            else {
              console.error('Address validation failed:', data.status);
              setErrorMessage('Whatsapp form not detected');
              setTimeout(() => {
                document.querySelector('.error-message').classList.add('fade-out');
              }, 1000); // Start fading out after 1 second

              setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
            }
            setIsLoading(false); // Stop loading
          })
          .catch(error => {
            console.error('Whatsapp form not detected', error);
            setErrorMessage('Whatsapp form not detected');
            setTimeout(() => {
              document.querySelector('.error-message').classList.add('fade-out');
            }, 1000); // Start fading out after 1 second

            setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
            setIsLoading(false); // Stop loading
            return;
          });
      } else {
        // Update state without address validation
        setCustomerInfo(prevState => ({
          ...prevState,
          personalInfo: {
            ...prevState.personalInfo,
            // ... (other personal info updates)
          },
          billAddress: {
            ...prevState.billAddress,
            // ... (other bill address updates)
          },
          installationDetails: {
            ...prevState.installationDetails,
            // ... (other installation details updates)
          }
        }));
      }
    } catch (error) {
      console.error('Whatsapp form not detected', error);
      setErrorMessage('Whatsapp form not detected');
      setTimeout(() => {
        document.querySelector('.error-message').classList.add('fade-out');
      }, 1000); // Start fading out after 1 second

      setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
    }
  };

  /*
  setCustomerInfo({
    personalInfo: {
      fullName: 'John Doe',
      icNumber: '1234567890',
      contactNumber: '1234567890',
      email: 'john.doe@example.com',
      whatsappNumber: '1234567890',
      homeNumber: '1234567890'
    },
    billAddress: {
      unitNo: '123',
      street: 'Main Street',
      section: '6B',
      postcode: '123456',
      city: 'Metropolis',
      state: 'WILAYAH PERSEKUTUAN',
      latitude: '12.3456',
      longitude: '65.4321'
    },
    installationDetails: {
      installationDate: 'ASAP',
      package: 'Unifi300',
    }
  });
  */

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the entire customerInfo structure up to the parent component
    onNext({ customerInfo });
  };

  return (

    <form className="customer-info-form" onSubmit={handleSubmit}>

      <h2>whatsapp form utility bill address</h2>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button type="button" onClick={handleMagicFill} className="magic-fill-button">
          Magic Fill
        </button>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {isLoading && <div className="loading-indicator"></div>}
      <br /><br /><br />
      <div className="input-group">
        <label htmlFor="fullName">Full Name</label>
        <input
          id="fullName"
          name="fullName"
          type="text"
          value={customerInfo.personalInfo.fullName}
          onChange={handleChange}
          placeholder="Full name"
        />

        <label htmlFor="icNumber">IC Number</label>
        <input
          id="icNumber"
          name="icNumber"
          type="text"
          value={customerInfo.personalInfo.icNumber}
          onChange={handleChange}
          placeholder="IC number"
        />

        <label htmlFor="contactNumber">Contact Number</label>
        <input
          id="contactNumber"
          name="contactNumber"
          type="text"
          value={customerInfo.personalInfo.contactNumber}
          onChange={handleChange}
          placeholder="Contact number"
        />

        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={customerInfo.personalInfo.email}
          onChange={handleChange}
          placeholder="Email"
        />

        <label htmlFor="whatsappNumber">WhatsApp Number</label>
        <input
          id="whatsappNumber"
          name="whatsappNumber"
          type="text"
          value={customerInfo.personalInfo.whatsappNumber}
          onChange={handleChange}
          placeholder="WhatsApp number"
        />

        <label htmlFor="homeNumber">Home Number (optional)</label>
        <input
          id="homeNumber"
          name="homeNumber"
          type="text"
          value={customerInfo.personalInfo.homeNumber}
          onChange={handleChange}
          placeholder="Home number"
        />
      </div>
      <div className="input-group">
        <h3>Billing Address</h3>

        <label htmlFor="unitNo">Unit Number</label>
        <input
          id="unitNo"
          name="unitNo"
          type="text"
          value={customerInfo.billAddress.unitNo}
          onChange={handleChange}
          placeholder="Unit number"
        />

        <div className="input-group">
          <label htmlFor="isHighRise">Is High-rise Building?</label>
          <input
            id="isHighRise"
            name="isHighRise"
            type="checkbox"
            checked={customerInfo.billAddress.isHighRise}
            onChange={handleChange}
          />
        </div>

        {customerInfo.billAddress.isHighRise && (
          <div className="input-group">
            {/* High-rise specific fields here */}
            <label htmlFor="buildingName">Building Name</label>
            <input
              id="buildingName"
              name="buildingName"
              type="text"
              value={customerInfo.billAddress.buildingName}
              onChange={handleChange}
              placeholder="Building Name"
            />
            <label htmlFor="floorNumber">Floor Number</label>
            <input
              id="floorNumber"
              name="floorNumber"
              type="text"
              value={customerInfo.billAddress.floorNumber}
              onChange={handleChange}
              placeholder="Floor Number"
            />
          </div>
        )}

        <label htmlFor="street">Street</label>
        <input
          id="street"
          name="street"
          type="text"
          value={customerInfo.billAddress.street}
          onChange={handleChange}
          placeholder="Street"
        />

        <label htmlFor="section">Section</label>
        <input
          id="section"
          name="section"
          type="text"
          value={customerInfo.billAddress.section}
          onChange={handleChange}
          placeholder="Section"
        />

        <label htmlFor="postcode">Postcode</label>
        <input
          id="postcode"
          name="postcode"
          type="text"
          value={customerInfo.billAddress.postcode}
          onChange={handleChange}
          placeholder="Postcode"
        />

        <label htmlFor="city">City</label>
        <input
          id="city"
          name="city"
          type="text"
          value={customerInfo.billAddress.city}
          onChange={handleChange}
          placeholder="City"
        />

        <label htmlFor="state">State</label>
        <select
          id="state"
          name="state"
          value={customerInfo.billAddress.state}
          onChange={handleChange}
        >
          <option value="">Select state</option>
          <option value="WILAYAH PERSEKUTUAN">WILAYAH PERSEKUTUAN</option>
          <option value="SELANGOR">SELANGOR</option>
          <option value="NEGERI SEMBILAN">NEGERI SEMBILAN</option>
          <option value="PERAK">PERAK</option>
          <option value="JOHOR">JOHOR</option>
          <option value="PERLIS">PERLIS</option>
          <option value="KEDAH">KEDAH</option>
          <option value="PULAU PINANG">PULAU PINANG</option>
          <option value="SABAH">SABAH</option>
          <option value="MELAKA">MELAKA</option>
          <option value="PAHANG">PAHANG</option>
          <option value="KELANTAN">KELANTAN</option>
          <option value="TERENGGANU">TERENGGANU</option>
          <option value="SARAWAK">SARAWAK</option>
          <option value="WILAYAH PERSEKUTUAN LABUAN">WILAYAH PERSEKUTUAN LABUAN</option>
        </select>

        <label htmlFor="latitude">Latitude</label>
        <input
          id="latitude"
          name="latitude"
          type="text"
          value={customerInfo.billAddress.latitude}
          onChange={handleChange}
          placeholder="Latitude"
        />

        <label htmlFor="longitude">Longitude</label>
        <input
          id="longitude"
          name="longitude"
          type="text"
          value={customerInfo.billAddress.longitude}
          onChange={handleChange}
          placeholder="Longitude"
        />
      </div>

      {/* Conditional rendering for ASAP or date input for installationDate */}
      <div className="input-group">
        <label htmlFor="installationDate">Installation Date</label>
        <input
          id="installationDate"
          name="installationDate"
          type="text"
          value={customerInfo.installationDetails.installationDate}
          onChange={handleDateChange}
          placeholder="MM/DD/YYYY or ASAP"
        />
      </div>

      {/* Package dropdown */}
      <div className="input-group">
        <label htmlFor="package">Package</label>
        <ReactSelect
          id="package"
          options={productOptions}
          onChange={handleSelectChange}
          value={selectedPackage}
          placeholder="Select or type to filter package"
          className="react-select-container"
          classNamePrefix="react-select"
          menuPlacement="top"
        />
      </div>

      <div className="sticky-step-navigation">
        <button onClick={onPrevious} className="back-button">
          <img src="assets/arrow-left.svg" alt="Go back" />
        </button>
        <button type="submit" className="next-button">
          <img src="assets/arrow-right.svg" alt="Go next" />
        </button>
      </div>

      <br />
      <br />
    </form >

  );
};

export default StepCustomerInfo;
