// LoginPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css'; // Make sure you create this CSS file

function LoginPage({ onLogin }) { // Accepting onLogin prop
  const [credentials, setCredentials] = useState({ id: '', pass: '' });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prevCredentials => ({
      ...prevCredentials,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: credentials.id, password: credentials.pass }),
      });

      const data = await response.json();
      if (response.ok) {
        // Here, instead of setting the token in local storage here, call the onLogin prop
        onLogin(data.token);
        navigate('/dashboard');
      } else {
        throw new Error(data.error || 'Login failed');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <img src="assets/cyberwolf.png" style={{maxWidth: '50px'}} />
        <br/><br/>
        <h1 className="login-title">wlvs.app</h1>
        
        <div className="login-group">
          <input 
            type="text" 
            name="id" 
            placeholder="id" 
            value={credentials.id} 
            onChange={handleChange} 
            required 
          />
        </div>
        
        <div className="login-group">
          <input 
            type="password" 
            name="pass" 
            placeholder="pass" 
            value={credentials.pass} 
            onChange={handleChange} 
            required 
          />
        </div>

        <button type="submit" className="login-button">enter →</button>
        {error && <div className="error-message">bad login</div>}
      </form>
    </div>
  );
}

export default LoginPage;
