// steps/StepDocuments.js

import React, { useState } from 'react';
import ImageKit from 'imagekit-javascript';
import './StepDocuments.css'; // Ensure this CSS file is created for styling

const imagekit = new ImageKit({
  publicKey: "public_XQWWuq0lGY5cXRAojyjPs6Nktaw=",
  urlEndpoint: "https://ik.imagekit.io/cyberwolves/"
});



const StepDocuments = ({ onUpload, onNext, onPrevious, orderData }) => {
  const [documentPreviews, setDocumentPreviews] = useState({
    idImageFront: { url: orderData.documents.idImageFront || '', isLoading: false },
    idImageBack: { url: orderData.documents.idImageBack || '', isLoading: false },
    billImage: { url: orderData.documents.billImage || '', isLoading: false },
    whatsappConvo: { url: orderData.documents.whatsappConvo || '', isLoading: false },
    mapCircleImage: { url: orderData.documents.mapCircleImage || '', isLoading: false },
  });


  const [isUploading, setIsUploading] = useState(false);

  const getAuthParameters = async () => {
    let response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/imagekit/getAuthParameters');
    let data = await response.json();
    console.log("Received Auth Params:", data);
    return data;
  };

  const uploadToImageKit = async (file) => {
    const authParameters = await getAuthParameters();
    console.log("Auth Params for Upload:", authParameters);

    return new Promise((resolve, reject) => {
      imagekit.upload({
        file: file,
        fileName: file.name,
        ...authParameters
      }, function (error, result) {
        if (error) reject(error);
        else {
          console.log('Upload Successful:', result);
          resolve(result.url); // This is the line that extracts the URL
        }
      });
    });
  };
  const handleFileChange = async (e, documentType) => {
    const file = e.target.files[0];
    if (file) {
      setDocumentPreviews(prevState => ({
        ...prevState,
        [documentType]: { ...prevState[documentType], isLoading: true }
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setDocumentPreviews(prevState => ({
          ...prevState,
          [documentType]: { ...prevState[documentType], url: reader.result }
        }));
      };
      reader.readAsDataURL(file);

      try {
        const uploadedUrl = await uploadToImageKit(file);
        console.log('Uploaded URL:', uploadedUrl);
        onUpload({ [documentType]: uploadedUrl });
        setDocumentPreviews(prevState => ({
          ...prevState,
          [documentType]: { url: uploadedUrl, isLoading: false }
        }));
      } catch (error) {
        console.error('Error uploading document:', error);
        setDocumentPreviews(prevState => ({
          ...prevState,
          [documentType]: { ...prevState[documentType], isLoading: false }
        }));
      }
    }
  };



  const handleNext = () => {
    const documentUrls = {
      idImageFront: orderData.documents.idImageFront,
      idImageBack: orderData.documents.idImageBack
      // more
    };
    onNext({ documents: documentUrls });
  };

  const handleBack = () => {
    onPrevious();
  };

  return (
    <div className="document-step-container">
      {isUploading && <div className="loader">Uploading...</div>} {/* Here is your loader */}
      <h2>documents</h2>
      <div className="documents-container">
        {Object.keys(documentPreviews).map((key) => (
          <div className="document-upload" key={key}>
            <label htmlFor={key}>{key.replace(/([A-Z])/g, ' $1').trim()}</label>
            <input
              id={key}
              type="file"
              onChange={(e) => handleFileChange(e, key)}
              style={{ display: 'none' }}
            />
            <label htmlFor={key} className="upload-area">
              {documentPreviews[key].isLoading ? (
                <div className="loader">Uploading...</div> // Loader for each document
              ) : documentPreviews[key].url ? (
                <img src={documentPreviews[key].url} alt={`${key} preview`} className="image-preview" />
              ) : (
                <div className="upload-placeholder">+</div>
              )}
            </label>
          </div>
        ))}
      </div>

      <div className="sticky-step-navigation">
        <button onClick={handleBack} className="back-button">
          <img src="assets/arrow-left.svg" alt="Go back" />
        </button>
        <button onClick={handleNext} className="next-button">
          <img src="assets/arrow-right.svg" alt="Go next" />
        </button>
      </div>
    </div>
  );
};

export default StepDocuments;