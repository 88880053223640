// SubmitOrderPage.js

import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import StepBrand from './steps/StepBrand'; // Component for step 1
import StepCategory from './steps/StepCategory'; // Component for step 2
import StepType from './steps/StepType'; // Component for step 3
import StepDocuments from './steps/StepDocuments'; // Component for step 4
import StepCustomerInfo from './steps/StepCustomerInfo'; // Component for step 5
import StepPortalAddress from './steps/StepPortalAddress'; // Component for step 6
import StepSuccess from './steps/StepSuccess'; // Component for step 7 (success message)
import './SubmitOrderPage.css'; // CSS for styling the page

const initialOrderData = {
  brand: '',
  category: '',
  type: '',
  documents: {
    idImageFront: null,
    idImageBack: null,
    billImage: null,
    whatsappConvo: null,
    mapCircleImage: null,
    passportImageFront: null,
    passportImageBack: null,
    businessDoc1: null,
    businessDoc2: null,
  },
  customerInfo: {
    personalInfo: {
      fullName: '',
      icNumber: '',
      contactNumber: '',
      email: '',
      whatsappNumber: '',
      homeNumber: '',
      isForeigner: false,
      isBusiness: false,
      passportNumber: '',
      businessName: '',
      businessRegNumber: '',
      businessType: ''
    },
    billAddress: {
      unitNo: '',
      street: '',
      section: '',
      postcode: '',
      city: '',
      state: '',
      latitude: '',
      longitude: '',
      isHighRise: false,
      floorNumber: '',
      buildingName: ''
    },
    installationDetails: {
      installationDate: '',
      package: '',
    },
  },
  portalAddress: {
    unitNumber: '',
    streetType: '',
    street: '',
    section: '',
    floorNumber: '',
    buildingName: '',
    city: '',
    postcode: '',
    state: '',
    addressStatus: '',
  },
};

const SubmitOrderPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [orderData, setOrderData] = useState(initialOrderData);
  const [portalAddressIsUpdated, setPortalAddressIsUpdated] = useState(false);

  const handleNextStep = (newData) => {
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      ...newData,
      documents: {
        ...prevOrderData.documents,
        ...newData.documents,
      },
    }));
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (currentStep === 6 && portalAddressIsUpdated) {
      handleSubmitOrder();
      setPortalAddressIsUpdated(false); // Reset the flag
    }
  }, [currentStep, portalAddressIsUpdated]); // Add dependencies here

  const handlePortalAddressSubmit = (data) => {
    setOrderData(prev => ({
      ...prev,
      portalAddress: data
    }));
    setPortalAddressIsUpdated(true); // Set the flag to true
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleDocumentUpload = (documentData) => {
    const documentType = Object.keys(documentData)[0];
    const url = documentData[documentType];

    setOrderData(prevOrderData => ({
      ...prevOrderData,
      documents: {
        ...prevOrderData.documents,
        [documentType]: url,
      },
    }));
  };

  const resetForm = () => {
    setCurrentStep(1);
    setOrderData(initialOrderData); // Assuming you have a state variable 'orderData' and 'initialOrderData' is the initial state
  };

  const handleSubmitOrder = async (e) => {

    // Decode the token to get the user's ID
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const userID = decodedToken._id;

    // Combine data from all steps into a single object
    const orderDataToSubmit = {
      userID: userID,
      productID: orderData.customerInfo.installationDetails.package, // should be determined earlier in the order process
      ssStatus: 'Submit in Progress', // initial status
      uppStatus: 'Adding to System', // initial status
      crmStatus: 'To Submit Data', // initial status
      details: {
        customerInfo: orderData.customerInfo.personalInfo, // use data from orderData state
        billAddress: orderData.customerInfo.billAddress, // use data from orderData state
        installationDetails: orderData.customerInfo.installationDetails, // use data from orderData state
        documents: orderData.documents, // use data from orderData state
        portalAddress: orderData.portalAddress,
      },
      // chatID: 'chatObjectId' // if applicable, or it will be set by the backend upon order creation
    };

    // POST request to server
    try {
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(orderDataToSubmit)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Could not create order');
      }

      // Handle success - maybe navigate to a success page or show a success message
      console.log('Order created successfully:', responseData);
      setCurrentStep(7); // Navigate to the success step
    } catch (error) {
      // Handle error - show error message to user
      console.error('Error creating order:', error);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <StepBrand onNext={handleNextStep} />;
      case 2:
        return <StepCategory onNext={handleNextStep} onPrevious={handlePreviousStep} />;
      case 3:
        return <StepType onNext={handleNextStep} onPrevious={handlePreviousStep} />;
      case 4:
        return <StepDocuments onUpload={handleDocumentUpload} onNext={handleNextStep} onPrevious={handlePreviousStep} orderData={orderData} />;
      case 5:
        return <StepCustomerInfo onNext={handleNextStep} onPrevious={handlePreviousStep} orderData={orderData} />;
      case 6:
        return (
          <StepPortalAddress
            onSubmit={handlePortalAddressSubmit}
            onPrevious={handlePreviousStep}
            orderData={orderData}
          />
        );
      case 7:
        return <StepSuccess onNewSubmission={resetForm} />;
      default:
        return <div>Unknown step</div>;
    }
  };

  return (
    <div className="submit-order-page">
      {renderStep()}
    </div>
  );
};

export default SubmitOrderPage;
