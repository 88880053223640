import React, { useEffect } from 'react';
import './StepBrand.css'; // Ensure to create and import the CSS

const StepBrand = ({ onNext }) => {
  const handleSelectBrand = (brand) => {
    onNext({ brand });
  };

  const handleKeyPress = (event) => {
    switch (event.key) {
      case '1':
        handleSelectBrand('unifi');
        break;
      case '2':
        handleSelectBrand('celcomdigi');
        break;
      case '3':
        handleSelectBrand('astro');
        break;
      // Add cases for other numbers if needed
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div>
      <h2>brand</h2>

      <div className="step-brand">
        <div className="brand-option" onClick={() => handleSelectBrand('unifi')}>
          <img src="/assets/brand1.png" alt="unifi" className="brand-icon" />
        </div>
        <div className="brand-option" onClick={() => handleSelectBrand('celcomdigi')}>
          <img src="/assets/brand2.png" alt="celcomdigi" className="brand-icon" />
        </div>
        <div className="brand-option" onClick={() => handleSelectBrand('astro')}>
          <img src="/assets/brand3.png" alt="astro" className="brand-icon" />
        </div>
        {/* ...additional brands as needed */}
      </div>

      <div className="sticky-step-navigation">
      </div>

    </div>
    
  );
};

export default StepBrand;
