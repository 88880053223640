// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import SubmitOrderPage from './pages/SubmitOrderPage';
import ActivitiesPage from './pages/ActivitiesPage';
import ChatView from './pages/ChatView';
import DetailsView from './pages/DetailsView';
import UserManagementPage from './pages/UserManagementPage';
import ProductManagementPage from './pages/ProductManagementPage';
import NavBar from './components/NavBar';
import './App.css'; // This CSS file should contain the layout styling

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token') ? true : false);

   // Add a new class when NavBar is not present
   const mainContentClass = isAuthenticated ? "main-content" : "main-content-full-width";

  // Update authentication status based on token changes
  useEffect(() => {
    setIsAuthenticated(localStorage.getItem('token') ? true : false);
  }, []);

  const handleLogin = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  return (
    <div className="app-container">
      <Router>
        {isAuthenticated && <NavBar onLogout={handleLogout} />} {/* Pass handleLogout as onLogout prop */}
        <main className={mainContentClass}>
          <Routes>
            <Route path="/" element={!isAuthenticated ? <LoginPage onLogin={handleLogin} /> : <Navigate to="/dashboard" />} />
            {isAuthenticated ? (
              <>
                <Route path="/dashboard" element={<DashboardPage onLogout={handleLogout} />} />
                <Route path="/submit-order" element={<SubmitOrderPage />} />
                <Route path="/activities" element={<ActivitiesPage />} />
                <Route path="/order/:orderId/chat" element={<ChatView />} />
                <Route path="/order/:orderId/details" element={<DetailsView />} />
                <Route path="/admin/users" element={<UserManagementPage />} />
                <Route path="/admin/products" element={<ProductManagementPage />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/" />} />
            )}
          </Routes>
        </main>
      </Router>
    </div>
  );
}

export default App;