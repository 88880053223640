// ProductForm.js

import React, { useState, useEffect } from 'react';

const ProductForm = ({ onSubmit, initialData = {}, onClose }) => {
  const [productData, setProductData] = useState({
    name: '',
    brand: '',
    category: '',
    type: '',
    value: '',
    priority: 0,
    isVisible: true, // Default to true
  });

  useEffect(() => {
    if (initialData) {
      setProductData({
        ...initialData,
        date: initialData.date ? new Date(initialData.date).toISOString().split('T')[0] : '',
        isVisible: initialData.isVisible !== undefined ? initialData.isVisible : true, // Ensure boolean value
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'select-one' && name === 'isVisible') {
      // Handle boolean conversion for isVisible
      setProductData({ ...productData, [name]: value === 'true' });
    } else {
      setProductData({ ...productData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(productData);
    onClose();
  };

  return (
    <div className="product-form-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Product Name</label>
        <input id="name" name="name" type="text" value={productData.name} onChange={handleChange} required />

        <label htmlFor="brand">Brand</label>
        <select id="brand" name="brand" value={productData.brand} onChange={handleChange} required>
          <option value="">Select Brand</option>
          <option value="Unifi">Unifi</option>
          <option value="Celcomdigi">Celcomdigi</option>
          <option value="Maxis">Maxis</option>
        </select>
        <br />
        <label htmlFor="category">Category</label>
        <select id="category" name="category" value={productData.category} onChange={handleChange} required>
          <option value="">Select Category</option>
          <option value="Home">Home</option>
          <option value="Business">Business</option>
        </select>
        <br />
        <label htmlFor="type">Type</label>
        <select id="type" name="type" value={productData.type} onChange={handleChange} required>
          <option value="">Select Type</option>
          <option value="New">New</option>
          <option value="Winback">Winback</option>
          <option value="Upgrade">Upgrade</option>
        </select>
        <br />
        <label htmlFor="value">Value</label>
        <input id="value" name="value" type="text" value={productData.value} onChange={handleChange} required />

        <label htmlFor="priority">Priority</label>
        <input id="priority" name="priority" type="number" value={productData.priority} onChange={handleChange} required />

        <label htmlFor="isVisible">Active Product?</label>
        <select id="isVisible" name="isVisible" value={productData.isVisible.toString()} onChange={handleChange} required>
          <option value="true">Show</option>
          <option value="false">Hide</option>
        </select>
        <br />
        <button type="submit">Save Product</button>
        <button type="button" onClick={onClose}>Cancel</button>
      </form>
    </div>
  );
};

export default ProductForm;
