// NavBar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import './NavBar.css';

const NavBar = ({ onLogout }) => {
  const userRole = getUserRole();
  const userName = getUserName();

  const handleLogout = () => {
    // Call the onLogout function passed down as a prop
    onLogout();
  };

  return (
    <aside className="nav-bar">
      <div style={{ justifyContent: 'center' }}>
        <img style={{ maxWidth: '120px' }} src="/assets/cyberwolf.png" alt="Logo" />
        <h3>// wlvs.app</h3>
        {userName && <h1>{userName} ({userRole})</h1>}
        <hr />
      </div>
      <nav>
        <ul className="nav-list">
          <li><NavLink to="/dashboard" activeClassName="active">dashboard</NavLink></li>
          <li><NavLink to="/submit-order" activeClassName="active">+ new submission</NavLink></li>
          <li><NavLink to="/activities" activeClassName="active">activities</NavLink></li>
          {userRole === 'superAdmin' && (
            <>
              <li><NavLink to="/admin/users" activeClassName="active">user management</NavLink></li>
              <li><NavLink to="/admin/products" activeClassName="active">product management</NavLink></li>
            </>
          )}
        </ul>
        <button onClick={onLogout} className="logout-button">logout</button> {/* Use onLogout directly */}
      </nav>
    </aside>
  );
};

export default NavBar;

function getUserRole() {
  const token = localStorage.getItem('token');
  if (!token) return null;
  try {
    const decoded = jwtDecode(token);
    return decoded.role;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}

function getUserName() {
  const token = localStorage.getItem('token');
  if (!token) return null;
  try {
    const decoded = jwtDecode(token);
    return decoded.username;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
}