// StatsDisplay.js

import React from 'react';
import './StatsDisplay.css'; // This will be your CSS file for styling

const StatsDisplay = ({ stats }) => {
  return (
    <div className="stats-display">
      <div className="stat-item">
        <span className="stat-title">today</span>
        <span className="stat-number">{stats.today}</span>
      </div>
      <div className="stat-item">
        <span className="stat-title">yesterday</span>
        <span className="stat-number">{stats.yesterday}</span>
      </div>
      <div className="stat-item">
        <span className="stat-title">daily avg</span>
        <span className="stat-number">{stats.dailyAvg}</span>
      </div>
      <div className="stat-item">
        <span className="stat-title">weekly avg</span>
        <span className="stat-number">{stats.weeklyAvg}</span>
      </div>
    </div>
  );
};

export default StatsDisplay;
