// UserForm.js

import React, { useState, useEffect } from 'react';

const UserForm = ({ onSubmit, initialData = {}, onClose }) => {
  const [userData, setUserData] = useState({
    username: '',
    password: '', // Only for adding new users
    role: '',
    // Add other fields as necessary
  });

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      setUserData({ ...initialData, password: '' }); // Reset password for edit
    } else {
      setUserData({ username: '', password: '', role: '' }); // Initialize for new user
    }
  }, [initialData]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(userData); // This sends the entire userData, including the role
    onClose(); // Close the form
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="username"
        value={userData.username}
        onChange={handleChange}
        placeholder="Username"
        required
      />
      <input
        type="password"
        name="password"
        value={userData.password}
        onChange={handleChange}
        placeholder="Password"
      />
      <select name="role" value={userData.role} onChange={handleChange}>
        <option value="">Select Role</option> {/* Added default option */}
        <option value="sales">Sales</option>
        <option value="teamLeader">Team Leader</option>
        <option value="superAdmin">Super Admin</option>
        <option value="staff">Staff</option>
        <option value="aftersales">After Sales</option>
      </select>
<br/><br/>
      {/* Add other fields as necessary */}
      <button type="submit">Submit</button><br/>
      <button type="button" onClick={onClose}>Cancel</button>
    </form>
  );
};

export default UserForm;
