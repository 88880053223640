// ProductManagementPage.js

import React, { useState, useEffect } from 'react';
import './ProductManagementPage.css'; // Make sure to create your CSS file for styling
import ProductForm from '../components/ProductForm'; // Assume this is a form component you will create

const ProductManagementPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editableProduct, setEditableProduct] = useState(null);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const openAddProductForm = () => {
    setEditableProduct(null);
    setIsFormOpen(true);
  };

  const openEditProductForm = (product) => {
    setEditableProduct(product);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setEditableProduct(null);
  };

  // Add, Edit, Delete handlers would go here
  // Handler for adding a new product
  const handleAddProduct = async (productData) => {
    try {
      const response = await fetch('https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(productData)
      });

      if (!response.ok) {
        throw new Error('Error creating product');
      }

      // Reload or update product list
      fetchProducts();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handler for editing an existing product
  const handleEditProduct = async (productId, productData) => {
    try {
      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products/${productId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(productData)
      });

      if (!response.ok) {
        throw new Error('Error updating product');
      }

      // Reload or update product list
      fetchProducts();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Handler for deleting an existing product
  const handleDeleteProduct = async (productId) => {
    if (!window.confirm('Are you sure you want to delete this product?')) {
      return;
    }

    try {
      const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products/${productId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Error deleting product');
      }

      // Reload or update product list
      fetchProducts();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="product-management-page">
      <h1>Product Management 🛜</h1>
      <button onClick={openAddProductForm}>Add Product</button>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Brand</th>
            <th>Category</th>
            <th>Type</th>
            <th>Date</th>
            <th>Value</th>
            <th>Priority</th>
            <th>Active?</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product._id}>
              <td>{product._id}</td>
              <td>{product.name}</td>
              <td>{product.brand}</td>
              <td>{product.category}</td>
              <td>{product.type}</td>
              <td>{product.date ? new Date(product.date).toLocaleDateString() : ''}</td>
              <td>{product.value}</td>
              <td>{product.priority}</td>
              <td>{product.isVisible ? '✅' : '❌'}</td>
              <td>
                <button onClick={() => openEditProductForm(product)}>Edit</button>
                <button onClick={() => handleDeleteProduct(product._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
  
      {isFormOpen && (
        <ProductForm
          initialData={editableProduct}
          onSubmit={editableProduct ? (data) => handleEditProduct(editableProduct._id, data) : handleAddProduct}
          onClose={closeForm}
        />
      )}
    </div>
  );
};

export default ProductManagementPage;
