// OrderTable.js

import React, { useEffect, useState } from 'react';

import './OrderTable.css'; // Make sure to create this CSS file for styling
const OrderTable = ({ orders, onSearchChange, searchTerm, token }) => {
  const [productNames, setProductNames] = useState({});
  const [usernames, setUsernames] = useState({});

  useEffect(() => {
    const fetchProductNames = async () => {
      const newProductNames = { ...productNames };
      let shouldUpdate = false;

      for (let order of orders) {
        if (!newProductNames[order.details.installationDetails.package]) {
          shouldUpdate = true;
          const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/products/${order.details.installationDetails.package}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const product = await response.json();
          newProductNames[order.details.installationDetails.package] = product.name;
        }
      }

      if (shouldUpdate) {
        setProductNames(newProductNames);
      }
    };

    const fetchUsernames = async () => {
      const newUsernames = { ...usernames };
      let shouldUpdate = false;

      for (let order of orders) {
        if (!newUsernames[order.userID]) {
          shouldUpdate = true;
          const response = await fetch(`https://wlvsapp-9c0a32cf7471.herokuapp.com/api/users/${order.userID}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const user = await response.json();
          newUsernames[order.userID] = user.username; // Assuming the user object has a username field
        }
      }

      if (shouldUpdate) {
        setUsernames(newUsernames);
      }
    };

    fetchProductNames();
    fetchUsernames();
  }, [orders, token]); // Removed productNames and usernames from dependency array

  return (
    <div className="order-table-container">
      <div className="search-bar">
        <input
          type="text"
          className="search-input"
          placeholder="Search customer name..."
          onKeyDown={onSearchChange}
        />
      </div>
      <table className="order-table">
        <thead>
          <tr>
            <th>date</th>
            <th>submitted by</th>
            <th>customer</th>
            <th>product</th>
            <th>ssid</th>
            <th>upp</th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order._id}>
                <td>{new Date(order.date).toLocaleString("en-US", {timeZone: "Asia/Kuala_Lumpur"})}</td>
                <td>{usernames[order.userID]}</td>
                <td>{`${order.details.customerInfo.fullName}`}</td>
                <td>{productNames[order.details.installationDetails.package]}</td>
                <td>{order.ssStatus}</td>
                <td>{order.uppStatus}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No orders found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
