// steps/StepPortalAddress.js

import React, { useState, useEffect } from 'react';
import './StepPortalAddress.css'; // Make sure to create and import the CSS

const StepPortalAddress = ({ onPrevious, onSubmit, orderData }) => {
  const [portalAddress, setPortalAddress] = useState(orderData.portalAddress || {
    unitNumber: '',
    streetType: '',
    street: '',
    section: '',
    floorNumber: '',
    buildingName: '',
    city: '',
    postcode: '',
    state: '',
    addressStatus: ''
  });

  useEffect(() => {
    // Initialize state with orderData if it exists
    if (orderData && orderData.portalAddress) {
      setPortalAddress(orderData.portalAddress);
    }
  }, [orderData]);

  const [errorMessage, setErrorMessage] = useState('');
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortalAddress({ ...portalAddress, [name]: value });
  };

  const handleMagicFill = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const values = text.split('\t');

      // Check if values array has enough elements
      if (values.length < 9) {
        // Handle error, e.g., show an error message to the user
        console.error('Portal address not detected in clipboard');
        setErrorMessage('Portal address not detected in clipboard');
        setTimeout(() => {
          document.querySelector('.error-message').classList.add('fade-out');
        }, 1000); // Start fading out after 1 second

        setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
        return;
      }

      // Assuming values are in the order as in your example
      const [unitNumber, streetType, street, section, floorNumber, buildingName, city, state, postcode] = values;

      // Set the portalAddress state
      setPortalAddress(prev => ({
        ...prev,
        unitNumber: unitNumber.trim(),
        streetType: streetType.trim(), // This assumes the value directly matches an option
        street: street.trim(),
        section: section.trim(),
        floorNumber: floorNumber.trim(),
        buildingName: buildingName.trim(),
        city: city.trim(),
        postcode: postcode.trim(),
        state: state.trim(), // This also assumes the value directly matches an option
      }));
    } catch (error) {
      console.error('Portal address not detected in clipboard', error);
      setErrorMessage('Portal address not detected in clipboard');
      setTimeout(() => {
        document.querySelector('.error-message').classList.add('fade-out');
      }, 1000); // Start fading out after 1 second

      setTimeout(() => setErrorMessage(''), 3000); // Clear the message after 4 seconds (including fade time)
    }
  };






  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(portalAddress); // Pass the current state up to the parent component
  };


  return (
    <form className="step-portal-address-form" onSubmit={handleSubmit}>
      <h2>portal address</h2>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <button type="button" onClick={handleMagicFill} className="magic-fill-button">
          Magic Fill
        </button>
      </div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <br /><br /><br />
      {/* Input fields for address */}

      {/* Unit Number */}
      <input
        type="text"
        name="unitNumber"
        value={portalAddress.unitNumber}
        onChange={handleChange}
        placeholder="Unit No."
      />

      {/* Street Type */}
      <select name="streetType" value={portalAddress.streetType} onChange={handleChange}>
        <option value="">Select Street Type</option>
        <option value="ALUR">ALUR</option>
        <option value="AVENUE">AVENUE</option>
        <option value="BATU">BATU</option>
        <option value="BULATAN">BULATAN</option>
        <option value="CABANG">CABANG</option>
        <option value="CERUMAN">CERUMAN</option>
        <option value="CERUNAN">CERUNAN</option>
        <option value="CHANGKAT">CHANGKAT</option>
        <option value="CROSS">CROSS</option>
        <option value="DALAMAN">DALAMAN</option>
        <option value="DATARAN">DATARAN</option>
        <option value="DRIVE">DRIVE</option>
        <option value="GAT">GAT</option>
        <option value="GELUGOR">GELUGOR</option>
        <option value="GERBANG">GERBANG</option>
        <option value="GROVE">GROVE</option>
        <option value="HALA">HALA</option>
        <option value="HALAMAN">HALAMAN</option>
        <option value="HALUAN">HALUAN</option>
        <option value="HILIR">HILIR</option>
        <option value="HUJUNG">HUJUNG</option>
        <option value="JALAN">JALAN</option>
        <option value="JAMBATAN">JAMBATAN</option>
        <option value="JETTY">JETTY</option>
        <option value="KAMPUNG">KAMPUNG</option>
        <option value="KELOK">KELOK</option>
        <option value="LALUAN">LALUAN</option>
        <option value="LAMAN">LAMAN</option>
        <option value="LANE">LANE</option>
        <option value="LANGGAK">LANGGAK</option>
        <option value="LEBOH">LEBOH</option>
        <option value="LEBUH">LEBUH</option>
        <option value="LEBUHRAYA">LEBUHRAYA</option>
        <option value="LEMBAH">LEMBAH</option>
        <option value="LENGKOK">LENGKOK</option>
        <option value="LENGKONGAN">LENGKONGAN</option>
        <option value="LIKU">LIKU</option>
        <option value="LILITAN">LILITAN</option>
        <option value="LINGKARAN">LINGKARAN</option>
        <option value="LINGKONGAN">LINGKONGAN</option>
        <option value="LINGKUNGAN">LINGKUNGAN</option>
        <option value="LINTANG">LINTANG</option>
        <option value="LINTASAN">LINTASAN</option>
        <option value="LORONG">LORONG</option>
        <option value="LOSONG">LOSONG</option>
        <option value="LURAH">LURAH</option>
        <option value="M G">M G</option>
        <option value="MAIN STREET">MAIN STREET</option>
        <option value="MEDAN">MEDAN</option>
        <option value="OFF JALAN">OFF JALAN</option>
        <option value="P.O.Box">P.O.Box</option>
        <option value="PARIT">PARIT</option>
        <option value="PEKELILING">PEKELILING</option>
        <option value="PERMATANG">PERMATANG</option>
        <option value="PERSIARAN">PERSIARAN</option>
        <option value="PERSINT">PERSINT</option>
        <option value="PERSISIRAN">PERSISIRAN</option>
        <option value="PESARA">PESARA</option>
        <option value="PESIARAN">PESIARAN</option>
        <option value="PIASAU">PIASAU</option>
        <option value="PINGGIAN">PINGGIAN</option>
        <option value="PINGGIR">PINGGIR</option>
        <option value="PINGGIRAN">PINGGIRAN</option>
        <option value="PINTAS">PINTAS</option>
        <option value="PINTASAN">PINTASAN</option>
        <option value="PO Box">PO Box</option>
        <option value="PUNCAK">PUNCAK</option>
        <option value="REGAT">REGAT</option>
        <option value="ROAD">ROAD</option>
        <option value="SEBERANG">SEBERANG</option>
        <option value="SELASAR">SELASAR</option>
        <option value="SELEKOH">SELEKOH</option>
        <option value="SILANG">SILANG</option>
        <option value="SIMPANG">SIMPANG</option>
        <option value="SIMPANGAN">SIMPANGAN</option>
        <option value="SISIRAN">SISIRAN</option>
        <option value="SLOPE">SLOPE</option>
        <option value="SOLOK">SOLOK</option>
        <option value="STREET">STREET</option>
        <option value="SUSUR">SUSUR</option>
        <option value="SUSURAN">SUSURAN</option>
        <option value="TAMAN">TAMAN</option>
        <option value="TANJUNG">TANJUNG</option>
        <option value="TEPIAN">TEPIAN</option>
        <option value="TINGGIAN">TINGGIAN</option>
        <option value="TINGKAT">TINGKAT</option>
      </select>

      {/* Street */}
      <input
        type="text"
        name="street"
        value={portalAddress.street}
        onChange={handleChange}
        placeholder="Street"
      />

      {/* Section */}
      <input
        type="text"
        name="section"
        value={portalAddress.section}
        onChange={handleChange}
        placeholder="Section"
      />

      {/* Floor Number */}
      <input
        type="text"
        name="floorNumber"
        value={portalAddress.floorNumber}
        onChange={handleChange}
        placeholder="Floor No."
      />

      {/* Building Name */}
      <input
        type="text"
        name="buildingName"
        value={portalAddress.buildingName}
        onChange={handleChange}
        placeholder="Building Name"
      />

      {/* City */}
      <input
        type="text"
        name="city"
        value={portalAddress.city}
        onChange={handleChange}
        placeholder="City"
      />

      {/* Postcode */}
      <input
        type="text"
        name="postcode"
        value={portalAddress.postcode}
        onChange={handleChange}
        placeholder="Postcode"
      />

      {/* State */}
      <select name="state" value={portalAddress.state} onChange={handleChange}>
        <option value="">Select State</option>
        <option value="JOHOR">JOHOR</option>
        <option value="KEDAH">KEDAH</option>
        <option value="KELANTAN">KELANTAN</option>
        <option value="MELAKA">MELAKA</option>
        <option value="NEGERI SEMBILAN">NEGERI SEMBILAN</option>
        <option value="PAHANG">PAHANG</option>
        <option value="PERAK">PERAK</option>
        <option value="PERLIS">PERLIS</option>
        <option value="PULAU PINANG">PULAU PINANG</option>
        <option value="SABAH">SABAH</option>
        <option value="SARAWAK">SARAWAK</option>
        <option value="SELANGOR">SELANGOR</option>
        <option value="TERENGGANU">TERENGGANU</option>
        <option value="WILAYAH PERSEKUTUAN">WILAYAH PERSEKUTUAN</option>
        <option value="WILAYAH PERSEKUTUAN LABUAN">WILAYAH PERSEKUTUAN LABUAN</option>
        <option value="WILAYAH PERSEKUTUAN PUTRAJAYA">WILAYAH PERSEKUTUAN PUTRAJAYA</option>
      </select>

      {/* Address Status */}
      <select name="addressStatus" value={portalAddress.addressStatus} onChange={handleChange}>
        <option value="">Select Address Status</option>
        <option value="All OK">All OK</option>
        <option value="Port Full">Port Full</option>
        <option value="Missing Address">Missing Address</option>
      </select>




      <div className="sticky-step-navigation">
        <button onClick={onPrevious} className="back-button">
          <img src="assets/arrow-left.svg" alt="Go back" />
        </button>
        <button type="submit" className="next-button">
          <img src="assets/check-double.svg" alt="Confirm" />
        </button>
      </div>
      <br />
      <br />
    </form>
  );
};

export default StepPortalAddress;
