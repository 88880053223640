import React, { useEffect } from 'react';
import './StepCategory.css'; // Make sure to import the CSS

const StepCategory = ({ onNext, onPrevious }) => {
  const handleSelectCategory = (category) => {
    onNext({ category });
  };

  const handleKeyPress = (event) => {
    switch (event.key) {
      case '1':
        handleSelectCategory('home');
        break;
      case '2':
        handleSelectCategory('business');
        break;
      case '`': // Using backtick (`) key for going back
        onPrevious();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="step-category">
      <h2>category</h2>
      <div className="cards-container">
      <div className="category-card" onClick={() => handleSelectCategory('home')}>
        <img src="/assets/cat1.svg" alt="Home" className="category-icon" />
        <span>home</span>
      </div>
      <div className="category-card" onClick={() => handleSelectCategory('business')}>
        <img src="/assets/cat2.svg" alt="Business" className="category-icon" />
        <span>business</span>
      </div>
      </div>

      <div className="sticky-step-navigation">
        <button className="back-button" onClick={onPrevious}>
          <img src="assets/arrow-left.svg" alt="Go back" />
        </button>
      </div>

    </div>
  );
};

export default StepCategory;
