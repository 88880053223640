import React, { useEffect } from 'react';
import './StepType.css'; // Make sure to create and import the corresponding CSS

const StepType = ({ onNext, onPrevious }) => {
  const handleSelectType = (type) => {
    onNext({ type });
  };

  const handleKeyPress = (event) => {
    switch (event.key) {
      case '1':
        handleSelectType('new');
        break;
      case '2':
        handleSelectType('winback');
        break;
      case '3':
        handleSelectType('upgrade');
        break;
      case '`': // Using backtick (`) key for going back
        onPrevious();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="step-type">
      <h2>type</h2>
      <div className="type-option" onClick={() => handleSelectType('new')}>
      <img src="/assets/type1.svg" alt="new" className="type-icon"/>
        <span className="type-name">new</span>
      </div>
      <div className="type-option" onClick={() => handleSelectType('winback')}>
      <img src="/assets/type2.svg" alt="winback" className="type-icon"/>
        <span className="type-name">winback</span>
      </div>
      <div className="type-option" onClick={() => handleSelectType('upgrade')}>
      <img src="/assets/type3.svg" alt="upgrade" className="type-icon"/>
        <span className="type-name">upgrade</span>
      </div>
      
      <div className="sticky-step-navigation">
        <button className="back-button" onClick={onPrevious}>
          <img src="assets/arrow-left.svg" alt="Go back" />
        </button>
      </div>
      
    </div>
  );
};

export default StepType;
